<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <div class="inter-content">
      <div class="orderinit-area container-fluid">
        <div class="order-area">
          <div class="hg-card text-center mt-4 mb-2">
            <img src="~@/assets/images/hg-card.png" alt="" class="img-fluid">
          </div>
          <div class="form-area mb-3">
            <input
              v-model="smsForm.id_no"
              v-validate="'required|min:4|max:4|integer'"
              class="box"
              placeholder="身分證末4碼"
              type="number"
              name="id_no"
              data-vv-as="身分證末4碼"
              data-vv-scope="smsForm"
              autocomplete="off"
            >
            <div v-if="errors.has('smsForm.id_no')" class="help-block">
              身分證末4碼 必須是數字
            </div>

            <input
              v-model="smsForm.mobile"
              v-validate="'required'"
              class="box"
              placeholder="手機"
              type="tel"
              name="mobile"
              data-vv-as="手機"
              data-vv-scope="smsForm"
              autocomplete="off"
            >
            <div v-if="errors.has('smsForm.mobile')" class="help-block">
              {{ errors.first('smsForm.mobile') }}
            </div>
          </div>
          <div class="btn-area px-3">
            <button v-b-modal.modal-contact class="btn btn-next" type="button" @click="sendSms">
              取得簡訊驗證碼
            </button>
          </div>

          <div v-if="smsSendAt">
            <div class="form-area mb-3">
              <input
                v-model="verifyForm.otpcode"
                v-validate="'required|integer'"
                class="box"
                placeholder="簡訊驗証碼"
                type="number"
                name="otpcode"
                data-vv-as="簡訊驗証碼"
                data-vv-scope="verifyForm"
                autocomplete="off"
              >
              <div v-if="errors.has('verifyForm.otpcode')" class="help-block">
                {{ errors.first('verifyForm.otpcode') }}
              </div>
            </div>
            <div class="btn-area px-3">
              <button v-b-modal.modal-contact class="btn btn-next" type="button" @click="sendVerify">
                確認送出
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { sendOTPSms, checkOTPSimpleIdNo } from '@/api/user'
// import jwt_decode from 'jwt-decode'

export default {
  name: 'Otp',
  components: {
    HeaderInter
  },
  data() {
    const smsForm = {
      mobile: null,
      id_no: null
    }

    const verifyForm = {
      caseid: null,
      otpcode: null
    }

    return {
      testJwt: null,
      smsForm: smsForm,
      verifyForm: verifyForm,
      smsSendAt: false
    }
  },
  created() {
    if (this.$store.getters.authInfo.third_verify) {
      // 無須otp
      this.$router.go(-1)
    } else {
      // this.testJwt = jwt_decode(this.$store.getters.authInfo.token)
      // console.log('jwt', this.testJwt)
    }
  },
  methods: {
    sendSms() {
      this.verifyForm.caseid = null
      this.$validator.validateAll('smsForm').then(valid => {
        if (valid) {
          sendOTPSms(this.smsForm.mobile, this.smsForm.id_no).then(response => {
            this.verifyForm.caseid = response.data.data.CASEID
            this.smsSendAt = Math.floor(Date.now() / 1000)
          }).catch(() => {
          })
        }
      })
    },
    sendVerify() {
      const self = this
      this.$validator.validateAll('verifyForm').then(valid => {
        if (valid) {
          checkOTPSimpleIdNo(this.verifyForm.caseid, this.verifyForm.otpcode).then(response => {
            this.$store.commit('SET_THIRD_VERIFY', true)
            self.$router.go(-1)
          }).catch(() => {
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "assets/scss/common.scss";
  .box-group {
    margin: 0 auto 1.5rem;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
    .box-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.85rem;
      margin-bottom: 0.25rem;
      // border-bottom: 2px solid rgba($white-color, $alpha: 1);
      .box-th {
        // border-left: 2px solid rgba($main-color, $alpha: 1);
        padding: 5px 15px 5px 0px;
        white-space: nowrap;
      }
      .box-td {
        text-align: right;
        padding-left: 10px;
      }
    }
  }

  .orderinit-area .order-area {
    .sub-title {
      text-align: center;
      font-size: 1rem;
      color: rgba($main-color, $alpha: 1);
      background: -webkit-gradient(
                      linear,
                      0 0,
                      0 100%,
                      color-stop(0.5, rgba($font-color, $alpha: 0.5)),
                      color-stop(0.5, transparent),
                      to(transparent)
      );
      background-repeat: repeat-x;
      background-position: center center;
      -webkit-background-size: 2px;
      display: block;
      margin: 0 auto 0.5rem;
      span {
        padding: 0 35px;
        background-color: $white-color;
      }
    }
    a {
      color: rgba($main-color, $alpha: 1);
    }
    a:hover {
      color: rgba($main-color, $alpha: 1);
      text-decoration: underline;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }

  .orderinit-area > .order-area:last-child {
    padding-bottom: 5rem;
  }

  .main {
    padding-bottom: 100px;
    p {
      margin: 0 0 0.5rem;
      padding: 0;
    }
    dt {
      color: $main-color;
      margin-bottom: 0.35rem;
    }
    dd {
      font-size: 0.85rem;
      line-height: 1.5rem;
      margin: 0;
      padding: 0;
    }
    dd span {
      color: $sec-color;
    }
    ol,
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      font-size: 0.85rem;
      .price {
        color: $notice-color;
      }
    }
  }

</style>
